<template>
  <div class="tree-c">
    <div id="echart_tree" style="width: 100%; height: 100%; margin: 25px"></div>
    <!-- <el-dialog
      :visible.sync="dialogVisible"
      width="300px"
      center
      class="dislog-title"
      :title="dialogTitle"
      :modal="false"
      :style="{ '--top': dialogTop, '--left': dialogLeft }"
    >
      <div class="dialog-content">{{ dialogContent }}</div>
    </el-dialog> -->

    <!-- <el-drawer
      class="drawer-body"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <div class="drawer-scroll">
        <div class="drawer-content" v-html="dialogContent"></div>
      </div>
    </el-drawer> -->

    <div
      :class="`drawer${dialogVisible ? ' show-drawer' : ''}`"
      @click.self="dialogVisible = false"
    >
      <div class="drawer-right">
        <div class="drawer-title">
          <span>{{ dialogTitle }}</span>
          <i class="close-btn el-icon-close" @click="dialogVisible = false"></i>
        </div>

        <div class="drawer-scroll2">
          <div
            class="drawer-content"
            style="padding-top: 20px"
            v-html="dialogContent"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import * as echarts from "echarts";
import "echarts-gl";
import { getSubNode, getNodeInfo } from "@/api/graph";

export default {
  name: "echart-tree",
  props: {
    treeData: {
      type: Object,
      required: true,
      handler(newVal, oldVal) {
        // if (newVal !== oldVal) {
        //   this.treeDataUpdated = true;
        // }
        this.treeDataUpdated = true;
      },
    },
  },
  data() {
    return {
      seriesArray: this.seriesData,
      //   tree_data:{},
      dialogVisible: false,
      dialogContent: "",
      dialogTitle: "",
      treeDataUpdated: true,

      dialogTop: "0px",
      dialogLeft: "0px",

      treeDataOption: {},
    };
  },

  computed: {},
  mounted: function () {
    this.handleInit();
  },
  watch: {
    treeData: {
      handler(newVal, oldVal) {
        this.treeDataOption = newVal;
        if (this.treeDataUpdated) {
          console.log("[watch]");
          if (!this.myChart) {
            this.handleInit();
          } else {
            this.myChart.clear();
            this.setChartOption();
          }

          this.treeDataUpdated = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    setChartOption() {
      const option = this.chartOption();
      console.log("this.treeDataOption", this.treeDataOption);
      this.myChart.setOption(option);
    },

    chartOption(auto) {
      return {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
        },
        series: [
          {
            type: "tree",
            data: [this.treeDataOption],
            layout: "orthogonal",
            edgeShape: "polyline",
            top: "1%",
            left: "7%",
            bottom: "1%",
            right: "20%",
            symbolSize: 7,
            // initialTreeDepth: 2,
            itemStyle: {},
            label: {
              position: "left",
              verticalAlign: "middle",
              align: "right",
              fontSize: 18,
              padding: [0, 10, 0, 10],
              formatter: function (params) {
                const length = params.treeAncestors
                  ? params.treeAncestors.length
                  : 0;
                if (
                  params.data.type === 2 &&
                  (!params.data.children || params.data.children.length === 0)
                ) {
                  return "{n|" + params.name + "}";
                } else if (length === 1) {
                  return "{a|" + params.name + "}";
                } else if (length === 2) {
                  return "{b|" + params.name + "}";
                } else if (length === 3) {
                  return "{c|" + params.name + "}";
                } else if (length === 4) {
                  return "{d|" + params.name + "}";
                } else if (length === 5) {
                  return "{e|" + params.name + "}";
                } else {
                  return "{f|" + params.name + "}";
                }
              },
              rich: {
                a: {
                  padding: 6,
                  borderRadius: 3,
                  color: "#fff",
                  backgroundColor: "#546fc6",
                },
                b: {
                  padding: 6,
                  borderRadius: 3,
                  color: "#fff",
                  backgroundColor: "#7ab1a6",
                },
                c: {
                  padding: 6,
                  borderRadius: 3,
                  color: "#fff",
                  backgroundColor: "#bec985",
                },
                d: {
                  padding: 6,
                  borderRadius: 3,
                  color: "#fff",
                  backgroundColor: "#fed360",
                },
                e: {
                  padding: 6,
                  borderRadius: 3,
                  color: "#fff",
                  backgroundColor: "#ed8f74",
                },
                f: {
                  padding: 6,
                  borderRadius: 3,
                  color: "#fff",
                  backgroundColor: "#91cd75",
                },
                n: {
                  padding: 6,
                  borderRadius: 3,
                  color: "#434343",
                  backgroundColor: "#fff",
                },
              },
            },
            lineStyle: {
              color: "#808080",
              // color: "#91cd75",
            },
            leaves: {
              label: {
                position: "right",
                verticalAlign: "middle",
                align: "left",
              },
            },
            emphasis: {
              // focus: "descendant",
              focus: "none",
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
      };
    },
    handleInit() {
      //   console.log(this.data)
      //   this.tree_data = this.data
      console.log("rock_test handleInit data = ", this.treeDataOption);
      this.handleSetVisitChart();
    },
    handleSetVisitChart() {
      let that = this;
      let option = null;
      echarts.util.each(this.treeDataOption.children, function (datum, index) {
        index % 2 === 0 && (datum.collapsed = true);
      });
      option = this.chartOption();

      let dom = document.getElementById("echart_tree");
      // dom.style.width = window.innerWidth * 0.69 + "px";
      // dom.style.height = window.innerHeight * 0.69 + "px";
      dom.style.width = window.innerWidth * 0.8 + "px";
      dom.style.height = window.innerHeight * 0.8 + "px";
      this.myChart = echarts.init(dom);

      // 基于准备好的dom，初始化echarts实例
      this.myChart.setOption(option, true);

      this.myChart.on("click", async (params) => {
        console.log("[点击节点]");
        await this.getChildrenData(params);

        if (!params.data.children || params.data.children.length === 0) {
          const top = params.event.event.clientY - 125;
          const left = params.event.event.clientX;

          if (top + 500 > window.innerHeight) {
            this.dialogTop = window.innerHeight - 450 + "px";
          } else {
            this.dialogTop = top + "px";
          }

          if (left + 350 > window.innerWidth) {
            this.dialogLeft = window.innerWidth - 350 + "px";
          } else {
            this.dialogLeft = left + "px";
          }
          this.dialogTitle = params.data.name;
          this.dialogVisible = true;
          this.dialogContent = '';
          const nodeText = await getNodeInfo({ id: params.data.id });
          if(nodeText){
            this.dialogContent = nodeText.contentHtml;
          }else {
            this.dialogContent = '暂无'
          }
          params.event.event.stopPropagation(); // 阻止事件的默认行为
        }

        if (params.componentType === "series") {
          // 点击到了 series 上
          if (!params.value) {
            // 点击的节点有子分支（可点开）
            var elesArr = Array.from(
              new Set(this.myChart._chartsViews[0]._data._graphicEls)
            );
            var height = 600; // 这里限制最小高
            var currentHeight = 20 * (elesArr.length - 1) || 200; // 每项10px
            var newHeight = Math.max(currentHeight, height);
            dom.style.height = newHeight + "px";
            setTimeout(() => {
              this.myChart.resize();
            }, 800);
          }
        }
      });
    },
    handleResize() {
      this.myChart.resize();
    },

    getDataObjById(id) {
      let dataObj = null;
      const findData = (data) => {
        if (data.id === id) {
          dataObj = data;
        } else {
          if (data.children && data.children.length > 0) {
            data.children.forEach((item) => {
              findData(item);
            });
          }
        }
      };
      findData(this.treeDataOption);
      return dataObj;
    },

    async getChildrenData(node) {
      // getSubNode
      console.log(node);
      if (
        node.data.type !== 1 ||
        (node.data.children && node.data.children.length > 0)
      ) {
        if (node.data.collapsed == undefined || node.data.collapsed == true) {
          console.log("未定义或者是未展开，下次即将展开");
          node.data.collapsed = false;
        } else {
          console.log("下次不展开");
          node.data.collapsed = true;
        }
        return;
      }
      // 获取子节点
      const res = await getSubNode({ pid: node.data.id });
      console.log(res);
      node.data.children = res;
      node.data.type = 2;
      node.data.collapsed = false;
      const data = this.myChart.getOption().series[0].data;
      console.log(data);
      this.treeDataOption = data[0];

      // const obj = this.getDataObjById(node.data.id);
      // obj.children = res;
      // console.log(obj);

      // 重载树形图
      this.myChart.clear();
      this.myChart.setOption({ animation: false });
      this.setChartOption();
      this.$nextTick(() => {
        this.myChart.setOption({ animation: true });
      });
    },
  },
};
</script>
  
<style scoped>
.dialog-content {
  max-height: 400px;
  overflow-y: auto;
}
.dislog-title /deep/.el-dialog {
  position: absolute;
  top: var(--top);
  left: var(--left);
  margin-top: 0;
}

.drawer-body /deep/ .el-drawer__header {
  margin-bottom: 20px;
}

.drawer-content {
  width: 100%;
  padding: 0 15px;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.drawer-scroll {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.drawer-scroll2 {
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: auto;
}

.tree-c {
  position: relative;
}

.drawer {
  position: fixed;
  top: 70px;
  right: -100vw;
  width: calc(100vw - 320px);
  height: calc(100vh - 91px);
  transition: all 0.4s;
}

.drawer-right {
  height: 100%;
  width: 700px;
  background: #fff;
  float: right;
}

.drawer-title {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.close-btn {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.show-drawer {
  right: 20px;
}
</style>
  
<style>
.drawer-content table {
  border: 1px solid #ccc;
}
.drawer-content table th,
.drawer-content table td {
  border: 1px solid #ccc;
}
.drawer-content table td {
  padding: 0px 5px;
}

.drawer-content table {
  margin-top: 30px;
  border-collapse: collapse;
  max-width: 100%;
}

.drawer-content th {
  background-color: #f2f2f2;
  font-weight: 700;
}

.drawer-content th,
.drawer-content td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.drawer-content td ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.drawer-content td ul li {
  margin-left: 30px;
}

.drawer-content td ul li:first-child {
  list-style-type: none;
  margin-left: 0;
}

.drawer-content h2 {
  margin-bottom: 5px;
  margin-top: 40px;
  font-weight: 400;
}

.drawer-content h3 {
  margin-bottom: 5px;
}

.drawer-content ul,
.drawer-content ol {
  margin: 5px 0;
}

.drawer-content section + section {
  margin-top: 15px;
}

.drawer-content blockquote {
  overflow: hidden;
  margin: 1em 0;
  padding: 0 32px;
  border-left: 3px solid var(--border-color-subtle, #c8ccd1);
}
</style>