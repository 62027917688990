<template>
  <div class="container" id="lcfz">
    <div class="aside">
      <side-bar ref="sideBar" class="sidebar-container" />
    </div>

    <section class="mainContent">
      <div class="mainContainer">
        <div class="chat-top">
          <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item v-for="(item, index) in treeIndex" :key="index">
                      <span style="cursor: pointer;" @click="checkTab(item, index)">{{item.name}}</span>
                    </el-breadcrumb-item>
                  </el-breadcrumb> -->
          <el-select
            v-model="curExerciseType"
            placeholder="请选择"
            @change="checkExerciseType"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in exerciseTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="card-body" ref="cardBodyRef" v-loading="pageLoading">
          <!-- <echart-tree
            :treeData="sportTreeData"
            ref="echartTreeRef"
          ></echart-tree> -->

          <zm-tree-org
            ref="tree"
            style="width: 100%"
            :props="{
              label: 'name',
            }"
            :data="sportTreeData"
            :horizontal="true"
            :label-style="treeStyle"
            :node-draggable="false"
            :default-expand-level="10"
            :toolBar="toolBar"
            :scalable="false"
            :draggable="false"
            :collapsable="true"
            :define-menus="[]"
            :lazy="true"
            :load="getTreeChildren"
            @on-node-click="clickTreeNode"
            v-if="!pageLoading"
          ></zm-tree-org>

          <!-- <ChartTree :treeData="sportTreeData" ref="echartTreeRef"></ChartTree> -->

          <!-- <waterfall :data="treeItem" :col="4" >
                      <template>
                        <div  class="box-card" style="padding: 0 10px;"  v-for="(item, index) in treeItem" :key="index">
                          <el-card>
                            <div slot="header" class="clearfix" @click="toChild(item, index)">
                              <span>{{item.name}}</span>
                            </div>
                            <div>
                              <p class="p-text" v-show="item.introduce">{{item.introduce}}</p>
                              <div v-if="item.children">
                                <p @click="toChild(item, index)" class="p-child" v-for="(citem, cindex) in item.children" :key="cindex">
                                  <i v-if="!citem.children || citem.children.length == 0" class="el-icon-tickets"></i>
                                  <i v-else class="el-icon-receiving"></i>
                                  <span>{{citem.name}}</span>
                                </p>
                              </div>
                            </div>
                          </el-card>
                        </div>
                      </template>
                    </waterfall> -->
        </div>
      </div>
    </section>

    <!-- <set-dialog :dialogSetVisible="dialogSetVisible"></set-dialog>
        <info-dialog :dialogSysVisible="dialogSysVisible"></info-dialog> -->

    <div
      :class="`drawer${dialogVisible ? ' show-drawer' : ''}`"
      @click.self="dialogVisible = false"
    >
      <div class="drawer-right-bg">
        <div class="drawer-right">
          <div class="drawer-title" ref="drawerTitle">
            <div class="drawer-scroll-title" style="text-align: center;font-size: 18px;padding: 0 30px;">{{ dialogTitle }}</div>
            <i
              class="close-btn el-icon-close"
              @click="dialogVisible = false"
            ></i>
          </div>

          <div class="drawer-scroll2" ref="drawerScroll2Ref" :style="{height: scrollHeight}">
            <!-- <div class="drawer-scroll-title" style="text-align: center;font-size: 18px;padding: 0 15px;font-weight: 550;">{{ dialogTitle }}</div> -->
            <div
              class="drawer-content"
              style="padding-top: 10px"
              v-html="dialogContent"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../../components/Sidebar/index.vue";
import SetDialog from "../../components/Sidebar/SetDialog.vue";
import InfoDialog from "../../components/Sidebar/InfoDialog.vue";
import EchartTree from "../../components/EchartTree/index.vue";
// import ChartTree from './chartTree.vue';
import { treeDatas } from "./treeJson.js";
import { getTree, getCategory, getSubNode, getNodeInfo } from "@/api/graph";



export default {
  name: "Process",
  components: {
    SideBar,
    //   SetDialog,
    //   InfoDialog,
    // EchartTree,
    // ChartTree,
  },
  data() {
    return {
      isLoding: true, // 加载中，loading图标
      // sportTreeData: {},
      curExerciseType: null,
      exerciseTypeList: [
        { id: 1, name: "有氧运动" },
        { id: 2, name: "搏击运动" },
        { id: 3, name: "冰雪运动" },
      ],
      sportTreeData: {},
      // sportTreeData: treeDatas.tree,

      treeIndex: [
        {
          index: 0,
          name: "有氧运动",
        },
      ],
      treeItem: [],
      treeStyle: {
        background: "#fff",
        color: "#5e6d82",
      },
      toolBar: {
        scale: false,
        restore: false,
        expand: false,
        zoom: false,
        fullscreen: false,
      },
      itemColor: ["#D9534F", "#FF964F", "#2795EE", "#249D7F", "#31B0D5"],
      dialogVisible: false,
      dialogTitle: "",
      dialogContent: "",

      pageLoading: true,
      scrollHeight: "calc(100% - 40px)",
    };
  },
  watch: {},

  created() {},
  mounted() {
    // this.handleChangeExerciseType(this.curExerciseType)
    // console.log("rock_test mounted, asdfas  ", this.sportTreeData)
    // this.treeItem = this.sportTreeData.children;
 
    this.getTreeSelect();
    this.$refs.sideBar.handleMenuClick(3);
  },
  activated() {
    this.$refs.sideBar.handleMenuClick(3);

    if(!this.sportTreeData){
      this.getTreeSelect();
    }
  },
  destroyed() {},
  methods: {
    async clickTreeNode(e, node) {
      if (node.type === 2) {
        // drawerScroll2Ref 滚动条回到顶部
        this.$refs.drawerScroll2Ref.scrollTop = 0;
        this.dialogVisible = true;
        this.dialogTitle = node.name;
        this.dialogContent = '';
        const nodeText = await getNodeInfo({ id: node.nid });
        if(nodeText){
          this.dialogContent = nodeText.contentHtml;
        }else {
          this.dialogContent = '暂无'
        }
        this.$nextTick(() => {
          this.scrollHeight = `calc(100% - ${this.$refs.drawerTitle.clientHeight}px)`;
        });
      }
    },

    scrollToRight() {
      this.$nextTick(() => {
        setTimeout(()=>{
          this.$refs.cardBodyRef.scrollLeft = this.$refs.cardBodyRef.scrollWidth;
        }, 50)
      });
    },

    async getTreeChildren(node, resolve) {
      // resolve([{ name: 'region', id: `123`, pid: node.id }])

      if (node.children && node.children.length > 0) {
        this.scrollToRight();
        return;
      }

      const res = await getSubNode({
        pid: node.id,
      });

      if (res && res.length > 0) {
        res.forEach((item) => {
          const colorIndex = Math.min(node.$$level + 1, 4);

          item.expand = false;
          item.isLeaf = item.type === 2;
          item.style =
            item.type === 1
              ? {
                  color: "#fff",
                  background: this.itemColor[colorIndex],
                  fontWeight: "600",
                }
              : {
                  color: "#4b5867",
                  background: "#EFF6F9",
                  fontWeight: "400",
                };
        });
      }

      resolve(res);
      this.scrollToRight();
      // return;
    },
    getTreeSelect() {
      getCategory()
        .then((res) => {
          this.exerciseTypeList = res;

          if (res && res.length > 0) {
            this.curExerciseType = res[0].id;
            this.getTreeList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkExerciseType(val) {
      this.getTreeList();
    },
    getExerciseByid(id) {
      return this.exerciseTypeList.find((item) => item.id == id);
    },
    getTreeList() {
      this.pageLoading = true;
      getSubNode({
        pid: this.curExerciseType,
      })
        .then((res) => {
          if (res && res.length > 0) {
            res.forEach((item) => {
              item.expand = false;
              item.isLeaf = item.type === 2;
              item.style =
            item.type === 1
              ? {
                  color: "#fff",
                  background: this.itemColor[1],
                  fontWeight: "600",
                }
              : {
                  color: "#4b5867",
                  background: "#EFF6F9",
                  fontWeight: "400",
                };
            });
          }

          let obj = {
            ...this.getExerciseByid(this.curExerciseType),
            children: res || [],
            expand: true,
            style: {
              color: "#fff",
              background: this.itemColor[0],
              fontWeight: "600",
            },
          };
          this.sportTreeData = obj;

          this.pageLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkTab(item, index) {
      this.treeIndex = this.treeIndex.slice(0, index + 1);
      let list = this.sportTreeData.children;

      this.treeIndex.forEach((i, d) => {
        if (d == 0) return;
        list = list[i.index].children;
      });

      this.treeItem = list;
    },
    toChild(item, index) {
      if (!item.children || item.children.length == 0) {
        return;
      }

      this.treeIndex.push({
        index: index,
        name: item.name,
      });
      this.treeItem = item.children;
    },

    handleSearch() {
      //搜索过滤数据
    },

    handleChangeExerciseType(event) {
      var exerciseTypeName = "有氧运动";
      if (event == 2) {
        exerciseTypeName = "搏击运动";
      }
      if (event == 3) {
        exerciseTypeName = "冰雪运动";
      }

      getTree({ node_name: exerciseTypeName, steps: 7 })
        .then((res) => {
          // if(res) {
          //    console.log("if res =", res.tree, this.sportTreeData)
          //   this.sportTreeData = res.tree
          // } else {
          //   this.sportTreeData = {}
          // }
          console.log("rock_test before, asdfas  ", this.sportTreeData);
          this.$nextTick(() => {
            console.log("rock_test planet .... nextTick ");
            this.$refs.echartTreeRef.handleInit();
            // this.$refs.echarts3d.handleInit()
          });
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped>
.process-main {
  height: 100%;
}
.chat-process {
  padding: 15px 20px;
  height: calc(100% - 148px);
  overflow-y: inherit;
}
.chat-process .el-tabs {
  margin-top: -65px;
  height: calc(100% + 65px);
}
/deep/.chat-process .el-tabs__header {
  margin: 0;
}
/deep/.chat-process .el-tabs__content {
  height: calc(100% - 48px);
}
/deep/.chat-process .el-tabs__content .el-tab-pane {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
/deep/.chat-process .el-tabs__nav-wrap::after {
  display: none;
}
/deep/.chat-process .el-tabs__item {
  padding: 0 20px !important;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  color: #333;
}
/deep/.chat-process .el-tabs__active-bar {
  width: 70px !important;
  margin-left: -20px;
  height: 4px;
  background-color: #4d82ff;
}
.chat-process .process-laws {
  display: flex;
  min-height: 100%;
}
/deep/.process-laws .el-loading-mask {
  height: 720px;
}
.process-doc {
  height: calc(100% - 20px);
}
.document-box {
  width: 960px;
  margin: 0 auto;
  padding-top: 20px;
}
.process-left {
  width: 190px;
  border-right: 1px solid #d2d5e8;
  padding: 3px 0;
}
.process-right {
  margin-left: 2px;
  border-left: 1px solid #d2d5e8;
  position: relative;
}
.process-right .process-card-list {
  display: flex;
  flex-wrap: nowrap;
  height: 48px;
}
.process-right .process-card .process-card-list.active-line:last-child::after {
  display: none;
}
.process-card-item .card-item-title {
  display: inline-block;
  width: 144px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 22px;
  line-height: 22px;
  position: relative;
  top: 7px;
}
/deep/.process-right .process-card-item .el-dropdown {
  width: 32px;
  height: 32px;
  float: right;
  margin-top: 2px;
  right: -7px;
  z-index: 9;
  border-radius: 4px;
}
/deep/.process-right .process-card-item:hover .el-dropdown {
  background-image: url(../../assets/icon/icon_more.png);
  background-repeat: no-repeat;
}
/deep/.process-right .process-card-item:hover .el-dropdown:hover {
  background-color: rgba(77, 130, 255, 0.3);
}
/deep/.process-right .process-card-item .el-dropdown-link {
  width: 32px;
  height: 32px;
  display: inline-block;
  font-size: 0 !important;
}
.process-right .process-card-item {
  background-color: #d2e4ff;
  font-size: 12px;
  color: #666;
  width: 198px;
  line-height: 36px;
  padding: 0 10px;
  margin: 10px 20px 0 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}
.process-right .process-card-item::after {
  content: " ";
  position: absolute;
  width: 40px;
  height: 10px;
  background-image: url(../../assets/image/arrow.png);
  background-repeat: no-repeat;
  right: -40px;
  top: 14px;
}
.process-right .process-card .process-card-item:last-child::after {
  display: none;
}
.process-right .process-card-item:hover {
  border-color: #4d82ff;
}
.process-right .process-card-item.is-active {
  color: #fff;
  border-color: #4d82ff;
  background-color: #4d82ff;
}
/deep/.process-right .process-card-item .el-button {
  border-color: transparent;
  background-color: transparent;
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
/deep/.process-right .process-card-item .el-button:hover {
  border-color: transparent;
  background-color: transparent;
}
.chat-process .process-laws-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  color: #3b5b86;
  font-size: 14px;
}
.chat-process .laws-cate-box {
  border-left: 4px solid #3b5b86;
}
.chat-process .laws-cate-box .laws-cate-item {
  height: 48px;
  line-height: 48px;
  width: 118px;
  padding: 0 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.active-line {
  margin-bottom: 21px;
  position: relative;
}
.active-line::after {
  position: absolute;
  content: " ";
  width: 100%;
  border-bottom: 1px dashed #d2d5e8;
  left: 20px;
  bottom: -16px;
}
.process-line {
  border-bottom: 1px dashed #d2d5e8;
  margin: 14px 20px 4px;
}
.process-menu {
  padding: 0;
  margin: -7px 0;
  list-style: none;
  width: 100%;
}
.process-menu li {
  margin: 0 -12px;
  padding: 5px 10px;
  font-size: 13px;
  color: #333;
  cursor: pointer;
}
.process-menu li:hover {
  background-color: #ebeef3;
}
.card-item-popover {
  position: absolute;
  /* display: none; */
  min-width: 300px;
  background-color: #fff;
  left: -1px;
  border: 1px solid #d2d5e8;
  border-radius: 4px;
  top: 45px;
  z-index: 99;
  padding: 10px;
}
.process-popover-content .process-popover-row,
.card-item-popover .process-popover-row {
  font-size: 12px;
  padding-bottom: 5px;
  overflow-y: hidden;
}
.process-popover-content .process-popover-row:last-child,
.card-item-popover .process-popover-row:last-child {
  padding-bottom: 0;
}
.process-popover-content .process-popover-row .row-title,
.card-item-popover .process-popover-row .row-title {
  float: left;
  width: 60px;
  color: #999;
}
.process-popover-content .process-popover-row .row-txt,
.card-item-popover .process-popover-row .row-txt {
  padding-left: 60px;
  color: #333;
}
.row-txt .el-button {
  font-size: 12px;
  background-color: transparent;
  padding: 0;
  border: none;
  color: #333;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: left;
  cursor: default;
  line-height: 18px;
}
.row-txt .el-button:hover {
  background-color: transparent;
}
.c3b {
  color: #3b5b86 !important;
}
.pb3 {
  padding-bottom: 3px;
}
.ml5 {
  margin-left: 5px;
}
.row-txt .pb3:last-child {
  padding-bottom: 0;
}
/* .process-popover-content .process-popover-row .row-txt span, */
.card-item-popover .process-popover-row .row-txt span {
  background-color: #ffd9d8;
}
.process-case {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
}
.process-case .process-case-item {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  width: 18.35%;
  margin: 15px 0 0 15px;
}
.process-case-item .case-item-top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 15px;
  background-color: #edf2ff;
  border: 1px solid #dde4fd;
  border-bottom: none;
  height: 36px;
  border-radius: 5px 5px 0 0;
  font-size: 14px;
}
.process-case-item .case-item-top.exist {
  background-color: #dae9ed;
  border: 1px solid #eaf7f0;
}
.case-item-top .arrow {
  width: 32px;
  height: 32px;
  background-image: url(../../assets/icon/icon_more.png);
  background-repeat: no-repeat;
}
.case-item-top .isTop {
  position: absolute;
  left: -1px;
  top: -1px;
  width: 24px;
  height: 24px;
  background-image: url(../../assets/icon/icon_top.png);
  background-repeat: no-repeat;
}
.process-case-item .case-item-content {
  border: 1px solid #edeffd;
  border-top: none;
  border-radius: 0 0 5px 5px;
  padding: 10px 15px;
  font-size: 12px;
  height: calc(100% - 36px);
}
.case-item-content .case-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.case-item-content .case-ul li {
  padding-bottom: 5px;
  overflow: hidden;
}
.case-item-content .case-ul li:last-child {
  padding-bottom: 0;
}
.case-ul .row-title {
  float: left;
  width: 60px;
  color: #999;
}
.case-ul .row-txt {
  color: #333;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.case-ul .clamp2 {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 36px;
  display: grid;
}
.case-ul .clamp3 {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 50px;
  display: grid;
}
.chat-search-box {
  margin-left: 10px;
}
/deep/.chat-top .el-input--medium .el-input__icon {
  line-height: 30px !important;
}
.chat-top .chat-top-collect {
  margin-left: 10px;
  width: 27px;
  height: 27px;
  padding: 0;
  border: 1px solid #d2d5e8;
  border-radius: 4px;
  background-image: url(../../assets/icon/icon_collect.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  cursor: pointer;
}
.chat-top .collected {
  background-image: url(../../assets/icon/icon_collect_active.png);
}
/deep/.chat-top .el-radio-group {
  padding-left: 10px;
}
/deep/.chat-top .el-radio-button--medium .el-radio-button__inner {
  background-color: #f0f4fb;
  width: 27px;
  height: 27px;
  padding: 0;
  border: 1px solid #d2d5e8;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 0;
}
/deep/.chat-top
  .el-radio-group
  .el-radio-button:first-child
  .el-radio-button__inner {
  background-image: url(../../assets/icon/icon_card.png);
}
/deep/.chat-top
  .el-radio-group
  .el-radio-button:last-child
  .el-radio-button__inner {
  background-image: url(../../assets/icon/icon_wd.png);
}
/deep/.chat-top
  .el-radio-group
  .el-radio-button:last-child
  .el-radio-button__inner.list-inner {
  background-image: url(../../assets/icon/icon_list.png);
}
/deep/.chat-top
  .el-radio-group
  .el-radio-button__orig-radio:checked
  + .el-radio-button__inner {
  background-color: #4d82ff;
  border-color: #4d82ff;
}
/deep/.chat-top
  .el-radio-group
  .el-radio-button:first-child
  .el-radio-button__orig-radio:checked
  + .el-radio-button__inner {
  background-image: url(../../assets/icon/icon_card_active.png);
}
/deep/.chat-top
  .el-radio-group
  .el-radio-button:last-child
  .el-radio-button__orig-radio:checked
  + .el-radio-button__inner {
  background-image: url(../../assets/icon/icon_wd_active.png);
}
/deep/.chat-top
  .el-radio-group
  .el-radio-button:last-child
  .el-radio-button__orig-radio:checked
  + .el-radio-button__inner.list-inner {
  background-image: url(../../assets/icon/icon_list_active.png);
}
/* 表格样式 */
.process-table {
  /* position: relative; */
}
.process-table .more {
  /* position: absolute;
  left: 50%;
  bottom: -10px; */
}
.process-table .el-table::before {
  display: none;
}
.process-table .el-table {
  background-color: transparent;
}
/deep/.process-table .el-table th,
/deep/.process-table .el-table tr {
  background-color: transparent;
}
/deep/.process-table .el-table th .el-table__column-filter-trigger {
  float: left;
  width: 14px;
  height: 14px;
  background-image: url(../../assets/icon/icon_filter.png);
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 4px 2px 0 0;
}
/deep/.process-table .el-table th .el-table__column-filter-trigger i {
  display: none;
  font-size: 0 !important;
}
/deep/.process-table .el-table td {
  border-bottom: none;
  background-color: rgba(77, 130, 255, 0.075);
  font-size: 12px;
  color: #333;
  /* padding: 9px 0; */
}
/deep/.process-tabl .el-table tr:hover td {
  background-color: transparent;
}
/deep/.process-table
  .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td {
  background-color: transparent;
}
/deep/.process-table .el-table td .el-tag {
  background-color: #d4e3ff;
  border-color: transparent;
  color: #333;
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
/deep/.process-table .el-table td .collect {
  background-image: url(../../assets/icon/icon_collect.png);
}
/deep/.process-table .el-table td .collected {
  background-image: url(../../assets/icon/icon_collect_active.png);
}
/deep/.process-table .el-table td .collect,
/deep/.process-table .el-table td .collected {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: center;
  display: inline-block;
  position: relative;
  top: 3px;
}
/deep/.process-table .el-table th.is-leaf {
  border-bottom: none;
  font-size: 12px;
  color: #999;
  font-weight: normal;
}
/deep/.process-table .el-table__body-wrapper {
  height: 718px;
  overflow-y: auto;
  padding-bottom: 30px;
}
.chat-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mainContent #chat_main {
  height: 100%;
}

.card-body {
  /* padding: 20px; */
  box-sizing: border-box;

  overflow-y: auto;
  height: calc(100% - 60px);
  padding-right: 40px;
  padding-bottom: 40px;
}

.card-body /deep/.el-card {
  background: rgba(255, 255, 255, 0.2);
}

.box-card {
  cursor: pointer;
}

.box-card + .box-card {
  margin-top: 20px;
}

/deep/.vue-waterfall {
  min-height: 600px;
  max-height: 800px;
  padding-bottom: 20px;
  overflow-y: auto;
}

.p-child {
  color: #838383;
  margin: 15px 0;
}

.p-child span {
  padding-left: 10px;
}

.p-text {
  line-height: 1.3;
}

.p-child:first-child {
  margin-top: 0;
}
.p-child:last-child {
  margin-bottom: 0;
}

.vue-waterfall-column {
  padding: 10px;
}

.zm-tree-org {
  background: none;
}

/deep/.tree-org-node__text {
  width: 160px;
  padding: 4px 5px !important;
  font-size: 16px;
  line-height: 1.4;
  /* font-weight: 600; */
}

/deep/.tree-org-node__content .tree-org-node__inner {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
/deep/.tree-org-node {
  padding-top: 7px;
  padding-bottom: 7px;
}

/deep/.tree-org-node.is-leaf {
  padding-top: 7px;
  padding-bottom: 7px;
}

/deep/.zm-tree-org .zoom-container {
  position: static;
  height: auto !important;
  width: auto;
  overflow: visible;
}

/deep/.zm-tree-org .zm-draggable {
  position: static;
}

/deep/.zm-draggable {
  transform: none !important;
}
/deep/.tree-org-node{
  padding-right: 15px;
}

.drawer {
  position: fixed;
  top: 70px;
  right: -100vw;
  width: calc(100vw - 320px);
  height: calc(100vh - 91px);
  transition: all 0.4s;
}

.drawer-right {
  height: 100%;
  width: 100%;
  position: relative;
  /* background-color: rgb(255, 176, 176); */
  background-image: linear-gradient(120deg, #fccb9025 0%, #c90e1725 100%);
  overflow: hidden;
  z-index: 10;
  /* 左侧shadow */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("~@/assets/image/beijing.png");
    filter: hue-rotate(245deg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    z-index: -1;
    opacity: 0.2;
  }
}

.drawer-right-bg {
  height: 100%;
  width: 700px;
  background: #fff;
  /* background: url("~@/assets/image/beijing.png"); */
  float: right;
}

.drawer-title {
  width: 100%;
  /* height: 20px; */
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.close-btn {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.show-drawer {
  right: 20px;
}

.drawer-content {
  width: 100%;
  padding: 0 15px;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.drawer-scroll2 {
  width: 100%;
  height: calc(100% - 20px);
  overflow-y: auto;
}
</style>

<style>

.drawer-content table {
  border: 1px solid #ccc;
}
.drawer-content table th,
.drawer-content table td {
  border: 1px solid #ccc;
}
.drawer-content table td {
  padding: 0px 5px;
}

.drawer-content table {
  margin-top: 30px;
  border-collapse: collapse;
  max-width: 100%;
}

.drawer-content th {
  background-color: #f2f2f29e;

  font-weight: 700;
}

.drawer-content th,
.drawer-content td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.drawer-content td ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.drawer-content td ul li {
  margin-left: 30px;
}

.drawer-content td ul li:first-child {
  list-style-type: none;
  margin-left: 0;
}

.drawer-content h2 {
  margin-bottom: 5px;
  margin-top: 40px;
  font-weight: 400;
}

.drawer-content h3 {
  margin-bottom: 5px;
}

.drawer-content ul,
.drawer-content ol {
  margin: 5px 0;
}

.drawer-content p{
  line-height: 1.5;
}

.drawer-content section + section {
  margin-top: 15px;
}

.drawer-content blockquote {
  overflow: hidden;
  margin: 1em 0;
  padding: 0 32px;
  border-left: 3px solid var(--border-color-subtle, #c8ccd1);
}

.el-select-dropdown.el-popper{
  margin-left: 0;
}
</style>